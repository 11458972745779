<template>
  <div>
    <AuditSummary />
  </div>
</template>

<script>
import AuditSummary from '@/components/AuditSummary.vue'

export default {
  components: {
    AuditSummary
  }
}
</script>
