<template>
  <b-icon
    :icon="icon"
    :type="type"
    :custom-class="customClass"
  />
</template>
<script>
export default {
  name: 'AuditState',
  props: {
    state: String,
    total: Number,
    editable: Boolean
  },
  data () {
    return {
      icons: {
        Closed: 'check',
        Running: 'sync-alt',
        Pending: 'play'
      }
    }
  },
  computed: {
    icon () {
      return this.icons[this.state] || 'times'
    },
    type () {
      return this.state === 'Closed' ? `is-${this.color(this.total)}` : ''
    },
    customClass () {
      return `${this.state === 'Running' ? 'fa-spin' : ''} ${this.state === 'Pending' && !this.editable ? 'has-text-grey-light' : ''}`
    }
  },
  methods: {
    color (value) {
      if (value > 69 && value <= 100) return 'success'
      if (value > 59) return 'warning'
      return 'danger'
    }
  }
}
</script>
