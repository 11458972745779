<template>
  <b-progress
    :value="calcTopicPercent"
    :type="calcTopicPercent | audtRatingTyp"
    show-value
    format="percent"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import filters from '@/mixins/filters'

import AuditService from '@/services/audits.service'

export default {
  props: {
    row: {
      required: true,
      type: Object
    },
    audit: {
      required: true,
      type: Object
    }
  },
  mixins: [filters],
  computed: {
    ...mapGetters({
      issueYear: 'manual/issueYear'
    }),
    calcTopicPercent () {
      if (this.audit.year === this.issueYear) {
        return AuditService.calcTopicPercent(this.audit.criteria, this.row.tnr)
      }
      return this.row.percent
    }
  }
}
</script>
