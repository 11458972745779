<template>
  <div v-if="audit">
    <Modal v-if="showUploadModal" @close="showUploadModal = false">
      <template #header>
        <h1 class="is-size-5 has-text-weight-bold">Audit hochladen</h1>
      </template>
      <template #body>
        Unterschriebenes Audit hochladen<br>
        <br>
        <div class="is-flex is-align-items-center is-justify-content-space-between">
          <b-field class="file ml-1 mb-0" :class="{'has-name': !!file}">
            <b-upload v-model="file" class="file-label" @input="upload">
              <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label">File aussuchen</span>
              </span>
              <span class="file-name" v-if="file">
                  {{ file.name }}
              </span>
            </b-upload>
          </b-field>
          <div>
            <div v-if="uploadError" class="is-flex is-align-items-center has-text-danger has-text-weight-bold">
              Error beim hochladen
              <b-icon icon="times" type="is-danger" class="mr-2" />
            </div>
            <b-icon v-if="uploadSuccess" icon="check" type="is-success" class="mr-2" />
          </div>
        </div>
        <b-progress
          :value="uploadPercent"
          class="mt-2"
          show-value
          type="is-success"
          format="percent"
        />
      </template>
      <template #footer>
        <b-button
          @click="closeModal"
          class="ml-2"
        >
          Schliessen
        </b-button>
      </template>
    </Modal>
    <div class="p-1 is-flex is-justify-content-space-between has-background-light is-align-items-center">
      <div class="is-flex is-align-items-center">
        <b-button v-if="canGoBack" @click="goBack" icon-right="arrow-left" class="mr-2" />
        <span class="pr-4">{{ audit.store.id }} - {{ audit.store.name }}</span>
        <span class="mr-3">{{ audit.cat | audtCatDescr }} {{ audit.year }}</span>
        <AuditState :state="audit.state" :total="calcTotalPercent" />
      </div>
      <div class="is-flex">
        <b-tooltip position="is-left" label="Test neu starten">
          <b-button :disabled="!isOnline" class="ml-1" v-if="audit.store.id == 999" icon-right="play" @click="restart" />
        </b-tooltip>
        <div v-if="showPrint" class="is-flex">
          <template v-if="audit.reportId">
            <b-tooltip position="is-left" label="Bericht drucken">
              <b-button
                :class="{ 'downloading' : downloadingPdf, 'downloaded' : downloadedPdf }"
                :icon-right="pdfIcon"
                :disabled="downloadingPdf || downloadedPdf || !isOnline"
                class="ml-1"
                @click="download"
              />
            </b-tooltip>
          </template>
          <template v-else>
            <b-tooltip position="is-left" label="Bericht drucken">
              <b-button
                :class="{ 'downloading' : downloadingPrint, 'downloaded' : downloadedPrint }"
                :icon-right="printIcon"
                :disabled="downloadingPrint || downloadedPrint || !isOnline"
                class="ml-1"
                @click="print"
              />
            </b-tooltip>
            <b-tooltip position="is-left" label="Bericht hochladen">
              <b-button
                v-if="['A', 'C'].includes(audit.cat)"
                class="ml-1"
                icon-right="upload"
                :disabled="!isOnline || !audit.editable"
                @click="showUploadModal = true"
              />
            </b-tooltip>
          </template>
          <b-tooltip v-if="audit.cat === 'S'" position="is-left" label="Bericht als Excel runterladen">
            <b-button
              :class="{ 'downloading' : downloadingExcel, 'downloaded' : downloadedExcel }"
              :icon-right="excelIcon"
              :disabled="downloadingExcel || downloadedExcel || !isOnline"
              class="ml-1"
              @click="excel"
            />
          </b-tooltip>
        </div>
      </div>
    </div>
    <table class="table is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th></th>
          <th>Themenblock</th>
          <th>Ergebnis</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, number) in topics"
          :key="number"
          class="is-clickable"
          :class="{ 'has-background-primary-light': $route.params.topicId == row.tnr }"
          @click="showDetails(row.tnr)"
        >
          <td class="has-text-right">{{ row.tnr }}</td>
          <td>{{ row.title }}</td>
          <td style="vertical-align: middle;">
            <AuditSummaryProgress :row="row" :audit="audit" />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th></th>
          <th>{{ footerTitle }}</th>
          <td>
            <b-progress
              :value="calcTotalPercent"
              :type="calcTotalPercent | audtRatingTyp"
              show-value
              format="percent"
            ></b-progress>
          </td>
        </tr>
      </tfoot>
    </table>
    <router-view :audit="audit"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import filters from '@/mixins/filters'

import ApiService from '@/services/api.service'
import AuditsService from '@/services/audits.service'

import AuditState from './AuditState.vue'
import AuditSummaryProgress from './AuditSummaryProgress.vue'
import Modal from './Modal.vue'

export default {
  name: 'Summary',
  components: {
    AuditState,
    AuditSummaryProgress,
    Modal
  },
  mixins: [filters],
  data () {
    return {
      uploadPercent: 0,
      file: null,
      audit: null,
      downloadingPrint: false,
      downloadedPrint: false,
      downloadingPdf: false,
      downloadedPdf: false,
      downloadingExcel: false,
      downloadedExcel: false,
      showUploadModal: false,
      uploadError: false,
      uploadSuccess: false
    }
  },
  watch: {
    $route () {
      this.loadData()
    }
  },
  computed: {
    ...mapGetters({
      manualTopics: 'manual/topics',
      issueYear: 'manual/issueYear',
      isOnline: 'global/isOnline',
      impersonated: 'auth/impersonated',
      hasOneOfRoles: 'auth/hasOneOfRoles'
    }),
    footerTitle () {
      return this.audit ? this.audit.state === 'Closed'
        ? 'Gesamtergebnis'
        : 'provisorisches Ergebnis'
        : ''
    },
    showPrint () {
      return this.audit && this.audit.state === 'Closed'
    },
    topics () {
      if (this.audit.year === this.issueYear) {
        return this.manualTopics.slice(0, this.manualTopics.length).sort((a, b) => a.tnr - b.tnr).filter(e => this.audit.criteria.filter(f => f.tnr === e.tnr).length)
      }
      return this.audit.topics || []
    },
    calcTotalPercent () {
      if (this.audit.year === this.issueYear) {
        return AuditsService.calcTotalPercent(this.audit.criteria)
      }
      return this.audit.total
    },
    canGoBack () {
      return this.audit.editable && this.audit.state === 'Running'
    },
    printIcon () {
      return this.downloadingPrint ? 'angle-double-down' : 'print'
    },
    pdfIcon () {
      return this.downloadingPdf ? 'angle-double-down' : 'file-pdf'
    },
    excelIcon () {
      return this.downloadingExcel ? 'angle-double-down' : 'file-excel'
    }
  },
  methods: {
    async download () {
      this.downloadingPdf = true
      try {
        await ApiService.getAuthorizedFile(`/audits/report/${this.audit.reportId}`)
      } catch (error) {
        console.log(error)
      }
      this.downloadingPdf = false
      this.downloadedPdf = true
      setTimeout(() => {
        this.downloadedPdf = false
      }, 4000)
    },
    async print () {
      this.downloadingPrint = true
      try {
        await ApiService.getAuthorizedFile(`/reports/audits/${this.$route.params.id}/pdf`)
      } catch (error) {
        console.log(error)
      }
      this.downloadingPrint = false
      this.downloadedPrint = true
      setTimeout(() => {
        this.downloadedPrint = false
      }, 4000)
    },
    async excel () {
      this.downloadingExcel = true
      try {
        await ApiService.getAuthorizedFile(`/audits/${this.$route.params.id}/excel`)
      } catch (error) {
        console.log(error)
      }
      this.downloadingExcel = false
      this.downloadedExcel = true
      setTimeout(() => {
        this.downloadedExcel = false
      }, 4000)
    },
    async upload () {
      try {
        await AuditsService.uploadFile(this.audit, this.file, this.updateUploadProcess)
        this.uploadSuccess = true
      } catch (error) {
        this.uploadPercent = 0
        this.uploadError = true
      }
    },
    updateUploadProcess (percent) {
      this.uploadPercent = percent
    },
    restart () {
      this.$buefy.dialog.confirm({
        title: 'Test neu starten',
        message: 'Wollen Sie wirklich einen neuen Test starten? Alle Testdaten werden gel\u00f6scht.',
        confirmText: 'Test neu starten',
        cancelText: 'Abbrechen',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.dropTest()
      })
    },
    async dropTest () {
      await ApiService.delete(`audits/${this.$route.params.id}`)
      await AuditsService.removeLocalAudit(this.audit)
      this.$router.push({ name: 'AuditEdit', params: { id: this.$route.params.id } })
    },
    async loadData () {
      const response = await AuditsService.getAudit(this.$route.params.id)
      // check if user can actually see audit according to audit status and user permissions
      if (response.state !== 'Closed' && !this.hasOneOfRoles(['QmsAuditor', 'QmsAdmin']) && response.cat !== 'S') {
        this.$router.push({ name: 'Audits' })
      }
      this.audit = response
    },
    showDetails (nr) {
      if (parseInt(this.$route.params.topicId) !== nr) {
        this.$router.push({ name: 'AuditTopic', params: { id: this.$route.params.id, topicId: nr } })
      }
    },
    goBack () {
      this.$router.push({ name: 'AuditEdit', params: { id: this.$route.params.id } })
    },
    closeModal () {
      this.showUploadModal = false
      this.discardFile()
    },
    discardFile () {
      this.file = null
      this.uploadPercent = 0
      this.uploadError = false
      this.uploadSuccess = false
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
